import { Loader, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';

import { fetchCompanies } from '../admin.service';
import Modal from './CompanyModal';

export function Companies() {
  const [companies, setCompanies] = useState<[any] | []>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState<any[] | undefined>(undefined);

  const [selectedCompanyId, setSelectedCompanyId] = useState(undefined);

  useEffect(() => {
    fetchCompanies().then((response) => {
      setCompanies(response.data);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!search) {
      setFilteredCompanies(undefined);
      return;
    }
    setFilteredCompanies(
      companies.filter(
        (company) =>
          String(company.nationalIdentifier).includes(search) ||
          String(company.name).toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  }, [search, companies]);

  const getCreator = (users: [any], id: string): any => {
    return users.filter((u) => u.id === id)[0];
  };

  if (isLoading) {
    return <Loader size="large" />;
  }

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <TextField
          labelHidden
          label="Search by ABN/ACN"
          placeholder="Search by ABN/ACN"
          onChange={({ target }) => setSearch(target.value)}
          value={search}
        />
      </div>

      {selectedCompanyId ? (
        <Modal onClose={() => setSelectedCompanyId(undefined)} companyId={selectedCompanyId} />
      ) : null}

      <Table highlightOnHover variation="striped">
        <TableHead>
          <TableRow>
            <TableCell as="th">Name</TableCell>
            <TableCell as="th">Trade type</TableCell>
            <TableCell as="th">Company type</TableCell>
            <TableCell as="th">Company Size</TableCell>
            <TableCell as="th">Created by</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(filteredCompanies || companies).map((company) => (
            <TableRow key={company.id} onClick={() => setSelectedCompanyId(company.id)}>
              <TableCell>
                {company.name} <br />
                {company.nationalIdentifierType} - {company.nationalIdentifier}
              </TableCell>
              <TableCell>{company.tradeType}</TableCell>
              <TableCell>{company.companyType}</TableCell>
              <TableCell>{company.companySize}</TableCell>
              <TableCell>{getCreator(company.users, company.createdBy).email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
