import { Heading, Loader, Text } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';

import { fetchCompanySummary } from '../admin.service';
import './CompanyModal.css';

const Modal = ({ onClose, companyId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [companySummary, setCompanySummary] = useState();

  useEffect(() => {
    fetchCompanySummary(companyId).then((response) => {
      setCompanySummary(response.data);
      setIsLoading(false);
    });
  }, [companyId]);

  if (isLoading || !companySummary) {
    return <Loader size="large" />;
  }

  const user = companySummary.companyProfile.users[0];

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          ×
        </button>

        <Heading level={5} style={{ textAlign: 'center', marginBottom: 20 }}>
          {companySummary.companyProfile.name}
        </Heading>

        <Text>
          Created by: {user.firstName} {user.lastName} ({user.phoneNumber})
        </Text>
        <Text>Quotes: {companySummary.quotes}</Text>
        <Text>Customers: {companySummary.customers}</Text>
        <Text>Price book items: {companySummary.priceBook}</Text>
        <Text>Invoices: {companySummary.invoices}</Text>
        <Text>Subscription ends on: {companySummary.subscriptionEndDate}</Text>
      </div>
    </div>
  );
};

export default Modal;
